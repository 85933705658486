import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';

import { Layout } from 'components/Layout/Layout';
import { editorialRequest } from 'store/actions/actions';
import { connect } from 'store/store';
import { BlockList } from 'components/BlockList/BlockList';
import { setServerResponseStatusCode } from 'helpers/setServerResponseStatusCode';
import { GET_EDITORIAL_RESPONSE } from 'store/actions/actionTypes';

class StartPage extends Component {
  static async getInitialProps({ ctx }) {
    const { store, isServer, query, locale } = ctx;
    const { preview } = query;
    const id = '';

    store.dispatch(editorialRequest({ id, preview, market: locale }));

    if (isServer) {
      await setServerResponseStatusCode(
        ctx,
        [GET_EDITORIAL_RESPONSE],
        'editorial',
        id
      );
    }

    return { isServer, id, query };
  }

  render() {
    const { currentPage, isMobile } = this.props;

    return (
      <Layout meta={get('metaData', currentPage)} url="">
        <BlockList
          isMobile={isMobile}
          data={get('blocks', currentPage)}
          hasMainHeading
        />
      </Layout>
    );
  }
}

StartPage.defaultProps = {
  currentPage: {},
  isMobile: false,
};

StartPage.propTypes = {
  currentPage: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default connect(StartPage, {}, (store, props) => ({
  currentPage: get(['editorial', 'data', props.id], store),
  isMobile: get('viewState.isMobile', store),
}));
